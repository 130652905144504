<template>
    <div>
        <b-card>
            <b-card-img :src="course.image" alt="Image" class="rounded-0 w-100"></b-card-img>

            <div class="plan-name">
                {{ course.name }}
            </div>
            <div class="plan-price mt-2 mb-2">
                <span>${{ course.price }}</span>/usd
            </div>
            <div class="custom-badged mb-2" v-if="course.category">
                {{ course.category.name }}
            </div>
            <div class="videos-quantity mb-2">
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M14.25 15.75H3.75C2.925 15.75 2.25 15.075 2.25 14.25V6C2.25 5.17125 2.92125 4.5 3.75 4.5H14.25C15.075 4.5 15.75 5.175 15.75 6V14.25C15.75 15.0788 15.0788 15.75 14.25 15.75Z" stroke="white" stroke-width="1.125" stroke-linecap="round" stroke-linejoin="round"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M8.25548 8.05657L10.9847 9.67057C11.3297 9.87457 11.3297 10.3741 10.9847 10.5781L8.25548 12.1921C7.90373 12.3998 7.45898 12.1463 7.45898 11.7383V8.51032C7.45973 8.10232 7.90448 7.84882 8.25548 8.05657V8.05657Z" stroke="white" stroke-width="1.125" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M3.58691 4.5V3.75C3.58691 2.92125 4.25816 2.25 5.08691 2.25H12.9132C13.7419 2.25 14.4132 2.92125 14.4132 3.75V4.5" stroke="white" stroke-width="1.125" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                {{ course.meta.totalSections }} Sections
            </div>
            <div class="video-date">
                <svg width="30" height="30" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M28.7923 17.5236C29.5824 18.3136 29.5824 19.5945 28.7923 20.3845C28.0023 21.1745 26.7214 21.1745 25.9314 20.3845C25.1414 19.5945 25.1414 18.3136 25.9314 17.5236C26.7214 16.7335 28.0023 16.7335 28.7923 17.5236" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M22.2024 13.9124C23.4189 15.1288 23.4189 17.1011 22.2024 18.3176C20.9859 19.5341 19.0136 19.5341 17.7971 18.3176C16.5806 17.1012 16.5806 15.1289 17.7971 13.9124C19.0136 12.6959 20.9859 12.6959 22.2024 13.9124" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M14.0687 17.5236C14.8587 18.3136 14.8587 19.5945 14.0687 20.3845C13.2787 21.1745 11.9978 21.1745 11.2078 20.3845C10.4177 19.5945 10.4177 18.3136 11.2078 17.5236C11.9978 16.7335 13.2787 16.7335 14.0687 17.5236" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M31.0002 27.0001V25.9041C31.0002 24.5231 29.8812 23.4041 28.5002 23.4041H27.6992" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M9 27.0001V25.9041C9 24.5231 10.119 23.4041 11.5 23.4041H12.301" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M25.3392 27V25.399C25.3392 23.466 23.7722 21.899 21.8392 21.899H18.1602C16.2272 21.899 14.6602 23.466 14.6602 25.399V27" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                {{ course && course.user ? course.user.username :'' }}
            </div>
            <div class="mt-3">
                <b-button class="w-100" variant="primary" :to="{ name: 'View-Course', params: {id: course.id, name: course.name } }">
                    See details
                </b-button>
            </div>
        </b-card>    
    </div>
</template>
<script>
import moment from 'moment'
export default {
    props: ['course'],
    methods: {
        formatDate (date) {
            let today = moment().format('Y-MM-DD')
            if(today == moment(date).format('Y-MM-DD')) {
                return 'Iniciado'
            } else {
                return moment(date).format('LL')
            }
        },
    }
}
</script>
<style scoped>
    .plan-name{
        font-weight: 300;
        font-size: 22px;
        line-height: 32px;
    }
    .plan-price{
        font-weight: 300;
        font-size: 22px;
        line-height: 32px;
    }
    .plan-price span {
        font-weight: 700;
        font-size: 26px;
        line-height: 32px;
    }
</style>